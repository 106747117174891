import { firestore } from "@/plugins/firebase"
import { collection, query, getDocs } from "firebase/firestore"

export default {
  state: {
    allAufschlaege: {},
    allAufschlaegeArray: []
  },
  mutations: {
    setAllAufschlaege(state, data) {
      state.allAufschlaege = data
    },
    setAllAufschlaegeArray(state, data) {
      state.allAufschlaegeArray = data
    }
  },
  actions: {
    async getAllBProducts({ commit }) {
      const datapath = "/produktaufschlaege"
      const tranchenRef = collection(firestore, datapath)
      const tranchenq = query(tranchenRef)

      try {
        const querySnapshot = await getDocs(tranchenq)
        const allAufschlaege = {}
        const allAufschlaegeArray = []

        querySnapshot.forEach((doc) => {
          const docdata = { id: doc.id, ...doc.data() }
          allAufschlaege[doc.id] = docdata
          allAufschlaegeArray.push(docdata)
        })

        commit("setAllAufschlaege", allAufschlaege)
        commit("setAllAufschlaegeArray", allAufschlaegeArray)
      } catch (error) {
        // Handle error here
        console.error("Error fetching data:", error)
      }
    }
  }
}
