import { firestore } from "@/plugins/firebase"
import {
    collection,
    query,
    onSnapshot
} from "firebase/firestore"

export default  {
    state: {
        allMails: {},
        allMailsArray:[]
    },
    mutations: {},
    actions: {
        async getAllMails({ state }) {
            const datapath = "/mail"
            const vOrderRef = collection(firestore, datapath)
            const vOrderq = query(vOrderRef)

            onSnapshot(vOrderq, (querySnapshot) => {
                state.allMailsArray=[];
                querySnapshot.forEach((doc) => {
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.allMails[doc.id] = docdata
                    state.allMailsArray.push(docdata)
                })
            })
        }
    }
}

