import Vue from "vue"
import Vuex from "vuex"

import main from "./modules/main"
import auth from "./modules/auth"
import comfortcontracts from "./modules/comfortcontracts"
import contract from "./modules/contract"
import users from "./modules/users"
import companys from "./modules/companys"
import einladungen from "./modules/einladungen"
import roles from "./modules/roles"
import produkte from "./modules/produkte"
import preisaufschlaege from "./modules/preisaufschlaege"
import produktaufschlaege from "./modules/produktaufschlaege.js"
import snackbar from "./modules/snackbar"
import vertraege from "./modules/vertraege"
import bestellungen from "./modules/bestellungen"
import mails from "./modules/mails"
import priceHistory from "./modules/priceHistory"
import recipientgroups from "./modules/recipientgroups"
import tradingtimes from "./modules/tradingtimes"




Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {},
    modules: {
        main,
        auth,
        comfortcontracts,
        contract,
        users,
        companys,
        einladungen,
        roles,
        produkte,
        preisaufschlaege,
        produktaufschlaege,
        snackbar,
        vertraege,
        bestellungen,
        mails,
        priceHistory,
        recipientgroups,
        tradingtimes
    }
})
