<template>
    <v-footer padless>
        <v-container>
            <v-row>
                <v-col class="py-4 text-center" cols="12">
                    © NEW {{ new Date().getFullYear() }}
                    <v-btn v-for="linkoben in linksoben" :key="linkoben.text" color="gray" text rounded class="my-2">
                        <a :href="linkoben.href" target="_blank"
                            style="text-decoration: none; color: inherit; display: contents;">
                            <template v-if="linkoben.icon != null">
                                <v-icon :href="linkoben.href" large dark>
                                    {{ linkoben.icon }}
                                </v-icon>
                            </template>
                            <template v-else>
                                {{ linkoben.text }}
                            </template>
                        </a>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col class="py-4 text-center white--text" cols="12">
                    <v-btn v-for="linkunten in linksunten" :key="linkunten.text" :href="linkunten.href" color="gray" text
                        rounded class="my-2">
                        <a :href="linkunten.href" target="_blank"
                            style="text-decoration: none; color: inherit; display: contents;">
                            <template v-if="linkunten.icon != null">
                                <v-icon dark>
                                    {{ linkunten.icon }}
                                </v-icon>
                            </template>
                            <template v-else>
                                {{ linkunten.text }}
                            </template>
                        </a>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "AppFooter",
    data() {
        return {
            linksoben: [
                {
                    text: "Facebook",
                    href: "https://www.facebook.com/new.niederrhein",
                    icon: "$facebookIcon"
                },
                {
                    text: "Instagram",
                    href: "https://www.instagram.com/new_gruppe",
                    icon: "$instagramIcon"
                },
                {
                    text: "Youtube",
                    href: "https://www.youtube.com/user/NEWniederrhein",
                    icon: "$youtubeIcon"
                },
            ],
            linksunten: [
                {
                    text: "NEW-Energie",
                    href: "https://www.new-energie.de/gk",
                    icon: null
                },
                {
                    text: "AGB",
                    href: "/agb",
                    icon: null
                },
                {
                    text: "Impressum",
                    href: "https://www.new-energie.de/impressum",
                    icon: null
                },
                {
                    text: "Datenschutz",
                    href: "https://www.new-energie.de/datenschutzhinweise",
                    icon: null
                },
                {
                    text: "rechtliche Hinweise",
                    href: "/rechtlicheHinweise",
                    icon: null
                }
            ]
        }
    },
    methods: {},
    computed: {},
    watch: {},
    mounted() { }
}
</script>

<style></style>