import { firestore } from "@/plugins/firebase"
import {
    doc,
    addDoc,
    updateDoc,
    setDoc,
    collection,
    query,
    onSnapshot,
} from "firebase/firestore"


export default {
    state: {
        recipientgroupsArray: [],
        id:0
    },
    mutations: {},
    actions: {
        async getRecipientGroups({ state }) {
            const datapath = "/mail_recipientgroups"
            const vOrderRef = collection(firestore, datapath)
            const vOrderq = query(vOrderRef)

            onSnapshot(vOrderq, (querySnapshot) => {
                state.recipientgroupsArray = [];
                querySnapshot.forEach((doc) => {
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.recipientgroupsArray.push(docdata)
                })
            })
        },
        async insertRecipientGroup({ state }, { groupdata }) {
            const datapath = "/mail_recipientgroups";
            const coldRef = collection(firestore, datapath);

            // Dokument hinzufügen und die automatisch generierte ID abrufen
            const docRef = await addDoc(coldRef, groupdata);
            // Die automatisch generierte ID in das Dokument schreiben
            await updateDoc(docRef, { id: docRef.id });
            console.log("Document written with ID: ", docRef.id);
            state.id++;
        },
        async updateRecipientGroup({ state }, { groupdata }) {
            const datapath = "/mail_recipientgroups";
            const docRef = doc(firestore, datapath, groupdata.id);
            const payload = {
                category: groupdata.category,
                shortname: groupdata.shortname,
                description: groupdata.description,
                members: groupdata.members,
            }
            await setDoc(docRef, payload, { merge: true });
            console.log("Document written with ID: ",payload, docRef.id);
            state.id++;
        }

    },

}

