import { initializeApp } from "firebase/app"
//import { getFirestore, connectFirestoreEmulator, setDoc, doc, collection } from "firebase/firestore"
//import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
//import { getAuth, connectAuthEmulator } from "firebase/auth"
import { getFirestore, setDoc, doc, collection } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
import { getAuth } from "firebase/auth"
import { FirebaseClient } from "@loginnew/firebase-client"
import store from "../store"
import router from "../router"

const firebaseApp = initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APPID
})

const functions = getFunctions(firebaseApp, "europe-west3")
const firestore = getFirestore(firebaseApp)

const auth = getAuth(firebaseApp)

/*if (location.hostname == "localhost") {
    connectFirestoreEmulator(firestore, "localhost", 8081)
    connectFunctionsEmulator(functions, "localhost", 5001)
    connectAuthEmulator(auth, "http://localhost:9099")
}*/

const client = new FirebaseClient(process.env.VUE_APP_LOGIN_NEW_CLIENT_ID, {
    functions,
    auth
}, {
    redirectPath: "/login"
})

// Wird beim Starten der Authentifizierung aufgerufen
client.onStartLoading(() => {
    store.state.auth.isAuthenticating = true
})
// Wird nach der Authentifizierung und nach onAuthStateChanged aufgerufen
// Wenn onAuthStateChanged async ist, wartet die Methode, bis alle Aktionen
// aus onAuthStateChanged abgeschlossen sind
client.onStopLoading(() => {
    store.state.auth.isAuthenticating = false
})
client.onAuthStateChanged(async ({ user, error }) => {
    //console.log("onAuthStateChanged user",user)
    //console.log("onAuthStateChanged firebaseApp",firebaseApp);
    //console.log("onAuthStateChanged client",client);
    store.state.auth.profile = user
    store.state.auth.isSignedIn = client.isSignedIn()

    if (error) {
        console.log(error)
        switch (error) {
            // Fehlercode aus dem Functions authClaims Rückgabewert (siehe functions.js)
            case "invalid_permissons": {
                if (router.currentRoute.name == "Anmeldung") {
                    break
                }
                const state = loginNew.getState()
                if (state && state.startsWith("register:")) {
                    router.push(`/anmelden?id=${state.replace("register:", "")}`)
                }
                break
            }
            default:
                router.push("/error")
                break
        }
    } else {
        if (client.isSignedIn()) {
            const authClaims = await client.getAuthClaims()
            //console.log("authClaims",authClaims)
            store.commit("setAuthClaims", authClaims)
            const userRef = doc(collection(firestore, "userprofiles"), user.sub)
            const data= {
                sub: user.sub,
                email: user.email,
                family_name: user.family_name,
                name: user.name,
                picture: user.picture
            }
            await setDoc(userRef, data, {merge: true})
            await store.dispatch("getUserprofile")
        } else {
            // Anmelden erzwingen (Silent-Auth funktioniert dennoch)
            //loginNew.auth()
        }
    }
})

export {
    firestore,
    functions,
    auth,
    client
}