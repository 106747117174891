import { functions } from "@/plugins/firebase"
import { httpsCallable } from "firebase/functions"

export default  {
    state: {
        pricehistory: []
        
    },
    mutations: {},
    getters: {
    },
    actions: {
        async getPriceHistory({ state }, {vertrag}) {
            const getChartData = httpsCallable(functions, "getChartData")
            const data = await getChartData({vertrag: vertrag})
            state.pricehistory=data.data
        }
    }
}



