var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24.001",
        height: "24",
      },
    },
    [
      _c("g", { attrs: { fill: "#22262f" } }, [
        _c("path", {
          attrs: {
            d: "M16.851 24H7.15A7.158 7.158 0 0 1 0 16.851v-9.7A7.157 7.157 0 0 1 7.15 0h9.7A7.157 7.157 0 0 1 24 7.149v9.7A7.158 7.158 0 0 1 16.851 24ZM7.15 2.414a4.741 4.741 0 0 0-4.736 4.735v9.7a4.741 4.741 0 0 0 4.736 4.735h9.7a4.741 4.741 0 0 0 4.735-4.735v-9.7a4.741 4.741 0 0 0-4.735-4.735Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "M12.501 19a6.5 6.5 0 1 1 6.5-6.5 6.506 6.506 0 0 1-6.5 6.5Zm0-10.47a3.971 3.971 0 1 0 3.972 3.97 3.976 3.976 0 0 0-3.972-3.971Z",
          },
        }),
        _c("circle", {
          attrs: {
            cx: "1.5",
            cy: "1.5",
            r: "1.5",
            transform: "translate(17.001 4)",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }