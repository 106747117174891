<template>
    <v-app>
        <v-snackbar v-model="$store.state.snackbar.snackbar" :color="$store.state.snackbar.snackbarColor">
            {{ $store.state.snackbar.snackbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="$store.state.snackbar.snackbar = false">
                    Schließen
                </v-btn>
            </template>
        </v-snackbar>
        <v-main>
            <appbar />
            <v-divider></v-divider>
            <div v-if="isLoading" class="text-center">
                <loading :active="isLoading" :color="'#78AEF5'" :can-cancel="false" :is-full-page="true" />
            </div>
            <div style="z-index:0; position: relative" v-else>

                <v-container>
                    <v-overlay opacity=0.80 :dark="$vuetify.theme.dark" :absolute="absolute"
                        :value="$store.state.main.menuoverlay" @click="onClickOverlay()">
                        <v-sheet color="white" style="width:100%">
                            <v-container>
                                <v-card flat>
                                    <v-card-text>
                                        <overlayMenu :category="$store.state.main.currentCategory"></overlayMenu>
                                    </v-card-text></v-card>
                            </v-container>
                        </v-sheet>
                    </v-overlay>
                    <router-view />
                </v-container>

            </div>
        </v-main>
        <appfooter></appfooter>
    </v-app>
</template>

<script>
import loading from "vue-loading-overlay"
import appbar from "./components/appbar.vue"
import overlayMenu from "./components/overlayMenu.vue"
import appfooter from "./components/appfooter.vue"


export default {
    name: "App",
    components: {
        loading,
        appbar,
        overlayMenu,
        appfooter
    },
    data: () => ({
        absolute: true
    }),
    methods: {
        onClickOverlay() {
            this.$store.state.main.menuoverlay = false

        },
    },
    computed: {
        isLoading() {
            return this.$store.state.auth.isAuthenticating
        }
    }
}
</script>
<style>
.v-overlay__content {
    width: 100%
}

.v-overlay__scrim {
    background: transparent linear-gradient(90deg, #004ba0 0%, #251f75 100%) 0 0 no-repeat padding-box;
    opacity: 0.98;
}

.v-overlay {
    align-items: flex-start;
}

.v-toolbar__content {
    z-index: 1;
}
</style>
