var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.$store.state.snackbar.snackbarColor },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            _vm.$store.state.snackbar.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Schließen ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.$store.state.snackbar.snackbar,
            callback: function ($$v) {
              _vm.$set(_vm.$store.state.snackbar, "snackbar", $$v)
            },
            expression: "$store.state.snackbar.snackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$store.state.snackbar.snackbarText) + " ")]
      ),
      _c(
        "v-main",
        [
          _c("appbar"),
          _c("v-divider"),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("loading", {
                    attrs: {
                      active: _vm.isLoading,
                      color: "#78AEF5",
                      "can-cancel": false,
                      "is-full-page": true,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticStyle: { "z-index": "0", position: "relative" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-overlay",
                        {
                          attrs: {
                            opacity: "0.80",
                            dark: _vm.$vuetify.theme.dark,
                            absolute: _vm.absolute,
                            value: _vm.$store.state.main.menuoverlay,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickOverlay()
                            },
                          },
                        },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { color: "white" },
                            },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("overlayMenu", {
                                            attrs: {
                                              category:
                                                _vm.$store.state.main
                                                  .currentCategory,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("router-view"),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c("appfooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }