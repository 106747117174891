import { firestore } from "@/plugins/firebase"
import {
    collection,
    query,
    onSnapshot
} from "firebase/firestore"

export default  {
    state: {
        allAufschlaege: {},
        allAufschlaegeArray:[]
    },
    mutations: {},
    getters: {
        getAufschlaegeArray: state => state.allAufschlaegeArray,
    },
    actions: {
        async getAllAufschlaege({ state }) {
            const datapath = "/preisaufschlaege/anbieter/Trianel"
            const tranchenRef = collection(firestore, datapath)
            const tranchenq = query(tranchenRef)

            onSnapshot(tranchenq, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.allAufschlaege[doc.id] = docdata
                    state.allAufschlaegeArray.push(docdata)
                })
            })
        }
    }
}

