import { firestore } from "@/plugins/firebase"
import {
    doc,
    getDoc
} from "firebase/firestore"

export default  {
    state: {
        allRoles: {},
        allRolesArray:[],
        rolesToLevel:{},
        levelToRoles:{}
    },
    mutations: {},
    actions: {
        async getAllRoles({ state,rootState }) {
            const stufe=rootState.auth.userprofile.level;
            console.log(stufe)
            const rolesDocRef=doc(firestore,"Einstellungen","Rollen")
            const docSnap = await getDoc(rolesDocRef);
            state.allRoles={}
            if (docSnap.exists()) {
                const roles = docSnap.data();
                for (const roleName in roles) {
                    if (roles[roleName].level > stufe) {
                        const d = { ...{name: roleName}, ...roles[roleName] }
                        state.allRoles[roleName]=d
                        state.allRolesArray.push(d)
                        state.rolesToLevel[roleName]=roles[roleName].level
                        state.levelToRoles[roles[roleName].level]=roleName
                    }
                    state.rolesToLevel[roleName]=roles[roleName].level
                    state.levelToRoles[roles[roleName].level]=roleName
                }

            } else {
                console.log("/Einstellungen/Rollen: No such document!");
            }
            
        },
       
    }
}

