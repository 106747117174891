/*import { firestore } from "@/plugins/firebase";
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    getDoc,
    documentId
} from "firebase/firestore";
*/
export default {
    state: {
        menuoverlay: false,
        currentCategory: null,
        navBarLinks: null
    },
    mutations: {

    },
    actions: {
 
    }
};
