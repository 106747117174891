var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: { xmlns: "http://www.w3.org/2000/svg", width: "34", height: "24" },
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "a" } }, [
          _c("path", { attrs: { fill: "none", d: "M0 0h34v24H0Z" } }),
        ]),
      ]),
      _c("g", { attrs: { fill: "#22262f" } }, [
        _c("path", { attrs: { fill: "none", d: "M0 0h34v24H0Z" } }),
        _c("g", { attrs: { "clip-path": "url(#a)" } }, [
          _c("path", {
            attrs: {
              d: "M26.939 24H7.061A7.061 7.061 0 0 1 0 16.956V7.044A7.061 7.061 0 0 1 7.061 0h19.878A7.061 7.061 0 0 1 34 7.044v9.912A7.061 7.061 0 0 1 26.939 24ZM12.7 7.074a.373.373 0 0 0-.373.373v9.123a.372.372 0 0 0 .534.336l9.3-4.424a.372.372 0 0 0 .008-.668l-9.3-4.7a.37.37 0 0 0-.169-.04Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }