import { firestore } from "@/plugins/firebase"
import {
    collection,
    query,
    where,
    getDocs,
    onSnapshot
} from "firebase/firestore"
import axios from "axios"

async function translateProductnamen(productname) {
    const energietyp={
        "G0": "Gas",
        "DE": "Strom"
    }
    //const produkttyp={
    //    "B": "Base",
    //    "P": "Peak"
    //}
    const produktzeitraum={
        "Y01": "Jahresprodukt",
        "Q01": "Quartalsprodukt (erstes Quartal)",
        "Q04": "Quartalsprodukt (zweites Quartal)",
        "Q07": "Quartalsprodukt (drittes Quartal)",
        "Q10": "Quartalsprodukt (viertes Quartal)",
        "M01": "Monatsprodukt (Januar)",
        "M02": "Monatsprodukt (Februar)",
        "M03": "Monatsprodukt (März)",
        "M04": "Monatsprodukt (April)",
        "M05": "Monatsprodukt (Mai)",
        "M06": "Monatsprodukt (Juni)",
        "M07": "Monatsprodukt (Juli)",
        "M08": "Monatsprodukt (August)",
        "M09": "Monatsprodukt (September)",
        "M10": "Monatsprodukt (Oktober)",
        "M11": "Monatsprodukt (November)",
        "M12": "Monatsprodukt (Dezember)"
    }

    // DEBY012022
    if (productname == null || productname == "null" || productname.length !== 10) {
        return "Ungültiger Produktname"
    }
    const re = /^(DE|G0)(B|P)((Y|Q|M)\d{2})(\d{4})$/;
    let regextest = re.test(productname)
    if (!regextest) { return "Fehler im Test" }
    let m = productname.match(re);
    //const output=`${energietyp[m[1]]} ${produkttyp[m[2]]} ${produktzeitraum[m[3]]} ${m[5]}`
    const output=`${energietyp[m[1]]} ${produktzeitraum[m[3]]} ${m[5]}`
    return output
}

async function getPrice(product, debug = false) {
    var data = new FormData()
    data.append("apikey", process.env.VUE_APP_FIREBASE_APIKEY)
    data.append("product", product)
    if (debug) {
        data.append("debug", "true")
    }

    var config = {
        method: "post",
        url: process.env.VUE_APP_FIREBASE_HOSTING + "/queryPrice",
        data: data
    }

    let response = await axios(config)
    return response.data
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export default {
    state: {
        isLoading: false,
        alleVertraege: {},
        aktuelleVertraege: {},
        aktuelleVertragsnamen: [],
        aktuellerVertrag: {},
        aktuellerVertragsname: "",
        aktuelleTranchenzeitraeume: [],
        aktuellerTranchenzeitraum: "",
        aktuelleTranche: {
            cost: 0,
            Preise: {
                base: { price: 0 },
                peak: { price: 0 }
            },
            Strukturkonstante: 0
        },
        aktuellerStoppLoss: [],
        geschlosseneTranchen: {},
        geschlosseneTranchenNamen: [],
        naechsterOffenerTranchenName: "",
        TrachenSummen: {
            volumeAllTranches: 0,
            volume: 0,
            cost: 0,
            price: 0,
            numberClosed: 0
        }
    },
    mutations: {},
    actions: {
        async holeStammdaten({ state }, { firmen }) {
            state.isLoading = true;
            const firmenids = firmen.map((f) => {
                //return parseInt(f.id)
                return f.id
            })

            const tranchenRef = collection(firestore, "Trancheninfos")
            const collRef = collection(firestore, "Stammdaten")
            console.log("firmenids", firmenids)
            const q = query(
                collRef,
                where("geschaeftspartnerNr", "in", firmenids)
            )
            const querySnapshot = await getDocs(q)
            for (const doc of querySnapshot.docs) {
                const docdata = { ...{ id: doc.id }, ...doc.data() }
                //state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer]["marktlokation"]=[];
                //state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer].anzahlMarktlokationen=0;
                if (!state.alleVertraege[docdata.geschaeftspartnerNr]) {
                    state.alleVertraege[docdata.geschaeftspartnerNr] = {}
                }
                if (!state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer]) {
                    const tranchenq = query(
                        tranchenRef,
                        where("Vertragsnummer", "==", docdata.vertragsnummer)
                    )
                    const tranchenSnapshot = await getDocs(tranchenq)
                    let tranchen = {}
                    for (const tdoc of tranchenSnapshot.docs) {
                        tranchen[tdoc.id] = tdoc.data()
                    }
                    state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer] = {
                        vertragsbeginn: docdata.vertragsbeginn,
                        vertragsende: docdata.vertragsende,
                        vertragsart: docdata.vertragsart,
                        commodity: docdata.commodity,
                        vertragsnehmer: docdata.vertragsnehmer,
                        geschaeftspartnerNr: docdata.geschaeftspartnerNr,
                        anzahlMarktlokationen: 0,
                        marktlokation: [],
                        tranchen: tranchen
                    }
                }
                state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer]["marktlokation"].push(docdata.marktlokation)
                state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer].anzahlMarktlokationen++
            }
            state.isLoading = false;
        },
        async holeVertragsdaten({ state }, { firma_id }) {
            //console.log("holeVertragsdaten", firma_id, state.alleVertraege[firma_id])
            state.aktuelleVertraege = Object.assign(
                {},
                state.alleVertraege[firma_id]
            )
            state.aktuelleVertragsnamen = Object.keys(state.aktuelleVertraege)
            state.aktuellerVertrag = Object.assign(
                {},
                state.aktuelleVertraege[state.aktuelleVertragsnamen[0]]
            )
            state.aktuellerVertragsname = state.aktuelleVertragsnamen[0]
            state.aktuelleTranchenzeitraeume = Object.keys(
                state.aktuellerVertrag["tranchen"]
            )
            state.aktuellerTranchenzeitraum =
                state.aktuelleTranchenzeitraeume[0]
        },
        async setAktuellerVertrag({ state }, { vertrag }) {
            state.aktuellerVertrag = state.aktuelleVertraege[vertrag]
            state.aktuelleTranchenzeitraeume = Object.keys(
                state.aktuellerVertrag["tranchen"]
            )
            state.aktuellerTranchenzeitraum =
                state.aktuelleTranchenzeitraeume[0]
        },
        async setAktuelleTranche({ state }, { tranchenzeitraum }) {
            state.aktuelleTranche =
                state.aktuellerVertrag["tranchen"][tranchenzeitraum]
            state.aktuelleTranche["Preise"] = {
                base: { price: 0 },
                peak: { price: 0 }
            }
            state.aktuelleTranche["Endpreis"] = {
                price: 0
            }
            state.aktuelleTranche["Strukturkonstante"] = 0
            state.aktuelleTranche["cost"] = 0
            state.aktuelleTranche["Preise"]["base"] = await getPrice(
                state.aktuelleTranche.Produkte.base
            )
            state.aktuelleTranche["Produkte"]["basetext"] = await translateProductnamen(state.aktuelleTranche.Produkte.base)
            if (state.aktuelleTranche.Produkte.peak) {
                state.aktuelleTranche["Preise"]["peak"] = await getPrice(
                    state.aktuelleTranche.Produkte.peak
                )
                state.aktuelleTranche["Produkte"]["peaktext"] = await translateProductnamen(state.aktuelleTranche.Produkte.peak)
            } else {
                state.aktuelleTranche["Preise"]["peak"] = { price: "0" }
            }

            let StrukturkonstanteinEuro =
                state.aktuelleTranche["StrukturkonstanteinEuro"]
            let TrancheMengeinkWh = state.aktuelleTranche["TrancheMengeinkWh"]
            if (typeof (StrukturkonstanteinEuro) == "string") {
                StrukturkonstanteinEuro = StrukturkonstanteinEuro.replace(".", "")
                StrukturkonstanteinEuro = StrukturkonstanteinEuro.replace(",", ".")
            }
            if (typeof (TrancheMengeinkWh) == "string") {
                TrancheMengeinkWh = TrancheMengeinkWh.replace(".", "")
                TrancheMengeinkWh = TrancheMengeinkWh.replace(",", ".")
            }

            state.aktuelleTranche["Strukturkonstante"] = parseFloat(
                parseFloat(
                    (
                        (parseFloat(StrukturkonstanteinEuro) / parseInt(TrancheMengeinkWh)) * 1000
                    ).toFixed(3)
                )
            )
        },
        async getAktuelleStoppLossDaten({ state }, { vertrag }) {
            state.aktuellerStoppLoss = [];
            const coll = `/Komfortbewirtschaftung/Stammdaten/Vertraege/${vertrag}/zeitbereiche`
            const collRef = collection(firestore, coll)
            const querySnapshot = await getDocs(collRef)
            let StoppLossData = []
            for (const doc of querySnapshot.docs) {
                const docdata = doc.data()
                StoppLossData.push({
                    "name": doc.id,
                    "stoppLoss": docdata.delta,
                    "limit": typeof(docdata.value) == "string" ? parseFloat(docdata.value) : docdata.value
                })
            }
            StoppLossData.sort((a,b) => a.limit - b.limit)
            state.aktuellerStoppLoss = StoppLossData
        },
        async calculatePrice({ state }) {
            let factorBase = state.aktuelleTranche.FaktorBase
            let factorPeak = state.aktuelleTranche.FaktorPeak
            factorBase = factorBase.replace(".", "")
            factorBase = factorBase.replace(",", ".")
            if (typeof factorPeak == "string") {
                factorPeak = factorPeak.replace(".", "")
                factorPeak = factorPeak.replace(",", ".")
            }
            console.log("calculatePrice",parseFloat(factorPeak), parseFloat(state.aktuelleTranche.Preise.peak.price) )
            state.aktuelleTranche["Endpreis"] = {
                base:
                    parseFloat(factorBase) *
                    parseFloat(state.aktuelleTranche.Preise.base.price),
                peak:
                    parseFloat(factorPeak) *
                    parseFloat(state.aktuelleTranche.Preise.peak.price),
                struct: state.aktuelleTranche.Strukturkonstante
            }
            state.aktuelleTranche["Endpreis"]["price"] = roundToTwo(
                state.aktuelleTranche["Endpreis"]["base"] +
                state.aktuelleTranche["Endpreis"]["peak"] +
                state.aktuelleTranche["Endpreis"]["struct"]);
            let TrancheMengeinkWh = state.aktuelleTranche["TrancheMengeinkWh"]
            TrancheMengeinkWh = TrancheMengeinkWh.replace(".", "")
            TrancheMengeinkWh = TrancheMengeinkWh.replace(",", ".")
            //const TrancheMengeinMWh = parseFloat(TrancheMengeinkWh) / 1000
            const TrancheMengeinMWh = parseInt(TrancheMengeinkWh) / 1000
            state.aktuelleTranche["cost"] =
                TrancheMengeinMWh * state.aktuelleTranche["Endpreis"]["price"];
        },
        async getOrderedTranches({ state }, { tranchenzeitraum }) {
            const datapath = `/Tranchenorders/${tranchenzeitraum}/orders`
            const tranchenRef = collection(firestore, datapath)
            const tranchenq = query(tranchenRef)

            onSnapshot(tranchenq, (querySnapshot) => {
                let tranchen = {}
                // TrachenSummen berechnen
                let countVolumen = 0
                let countCost = 0
                let countPriceAddon = 0
                let countPrice = 0
                let CountNumberClosed = 0
                querySnapshot.forEach((doc) => {
                    const tranche = doc.data()
                    tranche.pricing.price = tranche.pricing.price * 10 // Preisumrechnung ct/kWh zu €/MWh
                    CountNumberClosed++
                    countVolumen = countVolumen + tranche.volume
                    countCost = countCost + tranche.cost
                    countPriceAddon =
                        countPriceAddon + tranche.pricing.price
                    countPrice = countPriceAddon / CountNumberClosed
                    tranchen[doc.id.replace(" ", "_")] = tranche
                })
                state.TrachenSummen = {
                    volume: countVolumen.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    }),
                    cost: countCost.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    }),
                    price: countPrice.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    }),
                    numberClosed: CountNumberClosed,
                    numberAllTranches: state.aktuelleTranche.Tranchen.length,
                    volumeAllTranches: (parseInt(state.aktuelleTranche.TrancheMengeinkWh) * state.aktuelleTranche.Tranchen.length).toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    })
                }

                state.geschlosseneTranchen = tranchen
                state.geschlosseneTranchenNamen = Object.keys(tranchen)
                const counter = Object.keys(tranchen).length
                state.naechsterOffenerTranchenName =
                    state.aktuelleTranche.Tranchen[counter]


            })
        }
    }
}
