import { firestore } from "@/plugins/firebase";
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs
} from "firebase/firestore";


export default {
    namespaced: true,
    state: {
        alleVertraege: [],
        aktuelleVertragsnamen: [],
        alleTranchenNamen:[],
        aktuellerVertrag: {},
        trancheninfos:{},
        produkte: {},
        preise:{},
        aktuellerVertragsname:""
    },
    mutations: {
        set_alleVertraege(state, payload) {
            state.alleVertraege=payload
        },
        set_aktuelleVertragsnamen(state,payload) {
            state.aktuelleVertragsnamen=payload
        },
        set_aktuellerVertragsname(state,payload) {
            state.aktuellerVertragsname=payload
        },
        set_aktuellerVertrag(state,payload) {
            state.aktuellerVertrag=payload
        }
    },
    actions: {
        async holeStammdatenSoptim({ state,commit }, { firmen }) {
            state.isLoading = true;
            const firmenids = firmen.map((f) => {
                return f.id
            })

            const collRef = collection(firestore, "Stammdaten")
            console.log("firmenids", firmenids)
            const q = query(
                collRef,
                where("geschaeftspartnerNr", "in", firmenids),
                where("vertragsart", "==", "VariBuy")
            )
            const querySnapshot = await getDocs(q)
            let allDocs={};
            for (const qDoc of querySnapshot.docs) {
                const dData=qDoc.data()
                const docRef = doc(firestore, "soptim_vertraege", dData.vertragsnummer);
                const docSnap = await getDoc(docRef);
                let soptimData={}
                if (docSnap.exists()) {
                    // Dokument wurde gefunden, hier kannst du die Daten verwenden
                    soptimData=docSnap.data()
                } else {
                    console.log("not found",dData.vertragsnummer)
                }
                const preisregelung=soptimData.preisregelung[0].uuid;
                const prDocRef = doc(firestore, "soptim_tranchen", preisregelung);
                const prDocSnap = await getDoc(prDocRef);
                let prSoptimData={}
                if (prDocSnap.exists()) {
                    // Dokument wurde gefunden, hier kannst du die Daten verwenden
                    prSoptimData=prDocSnap.data()
                } else {
                    console.log("not found preisregelung",preisregelung)
                }
                const docdata = { ...{ id: qDoc.id }, ...dData, "soptim": soptimData, "soptimTranchen": prSoptimData}
                //allDocs.push(docdata)
                if (!allDocs[docdata.geschaeftspartnerNr]) {
                    allDocs[docdata.geschaeftspartnerNr] = {}
                }
                if (!allDocs[docdata.geschaeftspartnerNr][docdata.vertragsnummer]) {
                    allDocs[docdata.geschaeftspartnerNr][docdata.vertragsnummer] = []
                }
                allDocs[docdata.geschaeftspartnerNr][docdata.vertragsnummer]=docdata
            }
            commit("set_alleVertraege",allDocs)
        },
        async holeVertragsdaten({ state,commit  }, { firma_id }) {
            //console.log("holeVertragsdaten", firma_id, state.alleVertraege[firma_id])
            state.aktuelleVertraege = Object.assign(
                {},
                state.alleVertraege[firma_id]
            )
            const aktuelleVertragsnamen = Object.keys(state.aktuelleVertraege)
            commit("set_aktuelleVertragsnamen",aktuelleVertragsnamen)
            commit("set_aktuellerVertragsname",aktuelleVertragsnamen[0])
            commit("set_aktuellerVertrag",Object.assign(
                {},
                state.aktuelleVertraege[state.aktuelleVertragsnamen[0]]
            ))
        },
    }
};
