import { firestore } from "@/plugins/firebase"
import {
    collection,
    query,
    getDocs,
    onSnapshot
} from "firebase/firestore"

export default  {
    state: {
        allProducts: {},
        allProductsArray:[]
    },
    mutations: {},
    getters: {
        getProdukteArray: state => state.allProductsArray,
        getAnzeigeDatenAufschlaege(state) {
            return state.allProductsArray
        },
        mergedData: (state, getters) => {
            const moduleAData = getters.getProdukteArray;
            const moduleBData = getters.getAufschlaegeArray;
        
            // Verknüpfen Sie die Daten aus beiden Modulen anhand des Schlüssels
            const mergedData = moduleAData.map(itemA => {
              const correspondingItemB = moduleBData.find(itemB => itemB.id === itemA.id);
              const aufschlag=correspondingItemB ? correspondingItemB.gesammelteAufschlaege.normaufschlaegeWert : 0
              let aufschlagliste=correspondingItemB ? Object.keys(correspondingItemB.gesammelteAufschlaege.normaufschlaege) : []
              aufschlagliste.splice(aufschlagliste.indexOf("Standard"), 1)
              return {
                id: itemA.id,
                anbieter: itemA.anbieter,
                price: parseFloat(itemA.price)+aufschlag,
                handelsprice: itemA.price,
                timestamp: itemA.timestamp,
                aufschlag: aufschlag,
                aufschlagsliste: aufschlagliste.toString()
              };
            });
        
            return mergedData;
          }
    },
    actions: {
        async getAllProducts({ state }) {
            const datapath = "/beaufschlagteProdukte"
            const tranchenRef = collection(firestore, datapath)
            const tranchenq = query(tranchenRef)
            state.allProductsArray=[]
            onSnapshot(tranchenq, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.allProducts[doc.id] = docdata
                    state.allProductsArray.push(docdata)
                })
            })
        },
        async getFrontProducts({ state }) {
            const currentDate = new Date()
            const currentYear = currentDate.getFullYear()
            state.allProductsArray=[]
            // Berechne die Jahre für die Abfrage (das aktuelle Jahr und die nÃ¤chsten 3 Jahre)
            const years = [currentYear]
            const lines = ["B","P"]
            const potentialDocumentNames=[]
            for (let i = 1; i <= 3; i++) {
                years.push(currentYear + i)
            }

            years.forEach(year => {
                lines.forEach(line => {
                    potentialDocumentNames.push(`DE${line}Y01${year}`)
                })
            })
            const datapath = "/beaufschlagteProdukte"
            const tranchenRef = collection(firestore, datapath)

            // Abrufen aller Dokumente in der Sammlung
            const querySnapshot = await getDocs(tranchenRef)

            // Filtern der Dokumente, die den potenziellen Dokumentnamen entsprechen
            const relevantDocs = []
            querySnapshot.forEach((doc) => {
                if (potentialDocumentNames.includes(doc.id)) {
                    relevantDocs.push(doc)
                }
            })
        
            // Jetzt kÃ¶nnen Sie mit relevantDocs arbeiten, die die Dokumente fÃ¼r die kommenden Jahre enthalten
            relevantDocs.forEach((doc) => {
                // Hier wird Ihre Logik fÃ¼r jedes relevante Dokument fortgesetzt
                const docdata = { ...{ id: doc.id }, ...doc.data() }
                state.allProducts[doc.id] = docdata
                state.allProductsArray.push(docdata)

            })

        }
    }
}

