import { firestore } from "@/plugins/firebase";
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    getDoc,
    documentId
} from "firebase/firestore";

export default {
    state: {
        isSignedIn: false,
        isAuthenticating: true,
        profile: null,
        authClaims: {
            permissions: {
                admin: false,
                aufrufBewirtschaftung: false,
                aufrufAufschlagsadministration: false,
                aufrufSammelvertragskonfiguration: false,
                aufrufUserverwaltung: false,
                aufrufBestellverlauf: false,
                aufrufMailverlauf: false,
                aufrufLastgang: false,
                aufrufPreisuebersicht: false,
                aufrufWeitereUserEinladen: false
            }
        },
        userprofile: null,
        firmen: [],
        currentFirma: null
    },
    mutations: {
        setAuthClaims(state, claims) {
            //console.log("store auth state",state)
            console.log("store auth claims",claims)
            state.authClaims.permissions = claims.permissions
            //state.authClaims.permissions.admin = claims.permissions.admin == true;
            //state.authClaims.permissions.mailverlauf = claims.permissions.mailverlauf == true;
        },
        setFirma(state, firma) {
            state.currentFirma = firma;
        }
    },
    actions: {
        switchFirma({ commit }, firma) {
            commit("setFirma", firma);
        },
        async getUserprofile({ state }) {
            const docRef = doc(firestore, "userprofiles", state.profile.sub);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userdata = docSnap.data();
                state.userprofile = userdata;
                //console.log(userdata)
                const firmenliste = userdata ? userdata.firmen : [];
                const q = query(
                    collection(firestore, "Firmen"),
                    where(documentId(), "in", firmenliste)
                );
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    if (state.currentFirma === null) {
                        state.currentFirma = doc.data().geschaeftspartnerNr;
                    }
                    const docdata = { ...{ id: doc.id }, ...doc.data() };
                    state.firmen.push(docdata);
                });
            } else {
                console.log("Fehler beim Zugriff auf Benutzerprofil");
            }

        }
    }
};
