var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(_vm.getEntries, function (n) {
              return _c(
                "v-col",
                { key: n.title, attrs: { cols: "12", sm: "3" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-2", attrs: { flat: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticStyle: {
                            "text-decoration": "none",
                            color: "inherit",
                          },
                          attrs: { to: n.link },
                        },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-subtitle",
                                            {
                                              style: {
                                                color: hover
                                                  ? "#b40055"
                                                  : "black",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  style: {
                                                    color: hover
                                                      ? "#b40055"
                                                      : "black",
                                                  },
                                                },
                                                [_vm._v(" " + _vm._s(n.symbol))]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(n.title) + " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-card-text", [
                                            _vm._v(_vm._s(n.info)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }