var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { attrs: { padless: "" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-4 text-center", attrs: { cols: "12" } },
                [
                  _vm._v(" © NEW " + _vm._s(new Date().getFullYear()) + " "),
                  _vm._l(_vm.linksoben, function (linkoben) {
                    return _c(
                      "v-btn",
                      {
                        key: linkoben.text,
                        staticClass: "my-2",
                        attrs: { color: "gray", text: "", rounded: "" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              "text-decoration": "none",
                              color: "inherit",
                              display: "contents",
                            },
                            attrs: { href: linkoben.href, target: "_blank" },
                          },
                          [
                            linkoben.icon != null
                              ? [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        href: linkoben.href,
                                        large: "",
                                        dark: "",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(linkoben.icon) + " ")]
                                  ),
                                ]
                              : [_vm._v(" " + _vm._s(linkoben.text) + " ")],
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-4 text-center white--text",
                  attrs: { cols: "12" },
                },
                _vm._l(_vm.linksunten, function (linkunten) {
                  return _c(
                    "v-btn",
                    {
                      key: linkunten.text,
                      staticClass: "my-2",
                      attrs: {
                        href: linkunten.href,
                        color: "gray",
                        text: "",
                        rounded: "",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            "text-decoration": "none",
                            color: "inherit",
                            display: "contents",
                          },
                          attrs: { href: linkunten.href, target: "_blank" },
                        },
                        [
                          linkunten.icon != null
                            ? [
                                _c("v-icon", { attrs: { dark: "" } }, [
                                  _vm._v(" " + _vm._s(linkunten.icon) + " "),
                                ]),
                              ]
                            : [_vm._v(" " + _vm._s(linkunten.text) + " ")],
                        ],
                        2
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }