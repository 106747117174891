import { firestore } from "@/plugins/firebase"
import {
/*    doc,
    addDoc,
    updateDoc,
    setDoc,
    */
    collection,
    query,
    onSnapshot,
} from "firebase/firestore"


export default {
    state: {
        tradingTimes: {},
    },
    mutations: {},
    actions: {
        async getTradingTimes({ state }) {
            const datapath = "/tradingtimes"
            const vOrderRef = collection(firestore, datapath)
            const vOrderq = query(vOrderRef)

            onSnapshot(vOrderq, (querySnapshot) => {
                state.tradingTimesArray = [];
                querySnapshot.forEach((doc) => {
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.tradingTimes[doc.id]=docdata
                })
            })
        },
    },
}

