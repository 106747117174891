var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24" },
    },
    [
      _c("g", { attrs: { fill: "#22262f" } }, [
        _c("path", { attrs: { fill: "none", d: "M0 0h24v24H0z" } }),
        _c("path", {
          attrs: {
            d: "M23 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h11.75v-9h-3v-3.75h3v-3c0-3.1 1.963-4.625 4.728-4.625a25.828 25.828 0 0 1 2.794.143v3.24h-1.917c-1.5 0-1.855.715-1.855 1.763v2.479h3.75L19.5 15h-3l.06 9H23a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }