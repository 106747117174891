const state = {
    snackbar: false,
    snackbarText: "",
    snackbarColor: "success"
}

const mutations = {
    showSnackbar(state, { message = "Default message", status = "success" }) {
        console.log("called snackbar")
        state.snackbarText = message
        state.snackbarColor = status
        state.snackbar = true
    }
}

export default { state, mutations }
