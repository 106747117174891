import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: function () {
            return import(
                /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
            )
        },
        meta: {
            requiresAuth: false,
            requiresAdmin: false,
            showInDrawer: false,
            title: "Dashboard",
            
        }
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: function () {
            return import(
                /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            showInDrawer: true,
            title: "Startseite",
            symbol: "mdi-page-first",
            info: "Die Startseite von Energyview"
        }
    },
    {
        path: "/administration",
        name: "Administration",
        component: function () {
            return import(
                /* webpackChunkName: "administration" */ "../views/Administration.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: true,
            category: "Beschaffung",
            title: "Administration",
            symbol: "mdi-cog",
            info: "Konfiguration der Aufschläge",
            permission: "aufrufAufschlagsadministration"
        }
    },
    {
        path: "/usermgmnt",
        name: "Usermanagement",

        component: function () {
            return import(
                /* webpackChunkName: "usermgmnt" */ "../views/Usermanagement.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: true,
            category: "Vertrieb",
            title: "User",
            symbol: "mdi-account-group",
            info: "Benutzer einladen und verwalten",
            permission: "aufrufUserverwaltung"
        }
    },
    {
        path: "/userverwaltung",
        name: "UsermanagementKunde",

        component: function () {
            return import(
                /* webpackChunkName: "usermgmntkunde" */ "../views/UsermanagementKunde.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            showInDrawer: true,
            category: "Administration",
            title: "Benutzerverwaltung",
            symbol: "mdi-account-group",
            info: "Benutzer hinzufügen",
            permission: "aufrufUserverwaltungKunde"
        }
    },
    {
        path: "/anmelden",
        name: "Anmeldung",

        component: function () {
            return import(
                /* webpackChunkName: "anmelden" */ "../views/Anmeldung.vue"
            )
        },
        meta: {
            requiresAuth: false,
            requiresAdmin: true,
            showInDrawer: false,
            title: "User",
            symbol: "mdi-alert",
            info: "Zur Anmeldung"
        }
    },
    {
        path: "/bewirtschaftung",
        name: "Bewirtschaftung",

        component: function () {
            return import(
                /* webpackChunkName: "bewirtschaftung" */ "../views/Bewirtschaftung.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            showInDrawer: true,
            category: "Meine Bewirtschaftung",
            title: "Bewirtschaftung",
            symbol: "mdi-cart",
            info: "Verträge einsehen und bewirtschaften",
            permission: "aufrufBewirtschaftung"
        }
    },
    {
        path: "/komfortbewirtschaftung",
        name: "Komfortbewirtschaftung",

        component: function () {
            return import(
                /* webpackChunkName: "komfortbewirtschaftung" */ "../views/Komfortbewirtschaftung.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: false,
            category: "Administration",
            title: "Komfort",
            symbol: "mdi-view-list",
            info: "Zur Komfortbewirtschaftung",
            permission: "admin"
        }
    },
    {
        path: "/preise",
        name: "Preisdaten",

        component: function () {
            return import(
                /* webpackChunkName: "preise" */ "../views/Preisdaten.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: false,
            category: "Löschen",
            title: "Preisdaten",
            symbol: "mdi-currency-eur",
            info: "Alle Preisdaten ohne Aufschläge",
            permission: "aufrufPreisuebersicht"
        }
    },
    {
        path: "/beaufschlagtepreise",
        name: "beaufschlagte Preisdaten",

        component: function () {
            return import(
                /* webpackChunkName: "preise" */ "../views/BeaufschlagtePreisdaten.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            showInDrawer: true,
            category: "Meine Bewirtschaftung",
            title: "Aktuelle Preise",
            symbol: "mdi-human-dolly",
            info: "Preisdaten der NEW für die anstehenden Frontjahre",
            permission: "aufrufPreisuebersicht"
        }
    },
    {
        path: "/beaufschlagtepreiseadmin",
        name: "beaufschlagte Preisdaten",

        component: function () {
            return import(
                /* webpackChunkName: "preise" */ "../views/BeaufschlagtePreisdatenAdmin.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: true,
            category: "Meine Bewirtschaftung",
            title: "Aktuelle Preise mit Aufschlägen",
            symbol: "mdi-human-dolly",
            info: "Preisdaten der NEW für die anstehenden Frontjahre",
            permission: "aufrufPreisuebersicht"
        }
    },
    {
        path: "/aktuellelimits",
        name: "Aktuelle Limits",

        component: function () {
            return import(
                "../views/AktuelleLimits.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: true,
            category: "Meine Bewirtschaftung",
            title: "Aktuelle Limits",
            symbol: "mdi-chart-line",
            permission: "aufrufPreisuebersicht"
        }
    },
    {
        path: "/mailadministration",
        name: "MailAdministration",

        component: function () {
            return import(
                /* webpackChunkName: "mailadministration" */ "../views/MailAdministration.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            showInDrawer: true,
            category: "Administration",
            title: "Administration der Mailadressen",
            symbol: "mdi-email-arrow-right",
            info: "Administration der Mailadressen",
            permission: "admin"
        }
    },
    {
        path: "/einstellungen",
        name: "Einstellungen",

        component: function () {
            return import(
                /* webpackChunkName: "einstellungen" */ "../views/Einstellungen.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            showInDrawer: true,
            category: "Beschaffung",
            title: "Einstellungen",
            symbol: "mdi-wrench-cog",
            info: "Konfiguration der Sammelverträge",
            permission: "aufrufSammelvertragskonfiguration"
        }
    },
    {
        path: "/bestellverlauf",
        name: "Bestellverlauf",

        component: function () {
            return import(
                /* webpackChunkName: "bestellverlauf" */ "../views/Bestellverlauf.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: true,
            category: "Administration",
            title: "Bestellverlauf",
            symbol: "mdi-clipboard-text-clock",
            info: "Bestellübertragung ins SE-Sales",
            permission: "aufrufBestellverlauf"
        }
    },
    {
        path: "/mailverlauf",
        name: "Mailverlauf",

        component: function () {
            return import(
                /* webpackChunkName: "mailverlauf" */ "../views/Mailverlauf.vue"
            )
        },
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            showInDrawer: true,
            category: "Administration",
            title: "Mailverlauf",
            symbol: "mdi-email-arrow-right",
            info: "Übersicht des Mailversands",
            permission: "aufrufMailverlauf"
        }
    },
    {
        path: "/login",
        name: "login",

        component: function () {
            return import(/* webpackChunkName: "login" */ "../views/login-view.vue")
        },
        meta: {
            requiresAuth: false,
            requiresAdmin: false,
            showInDrawer: false,
            category: "Administration",
            title: "Login",
            symbol: "mdi-login",
            info: "Zum Login"
        }
    },
    {
        path: "/agb",
        name: "AGBs",

        component: function () {
            return import(
                /* webpackChunkName: "agbs" */ "../views/AGBs.vue"
            )
        },
        meta: {
            requiresAuth: false,
            requiresAdmin: false,
            showInDrawer: false,
            category: "",
            title: "",
            symbol: "",
            info: ""
        }
    },
    {
        path: "/rechtlichehinweise",
        name: "Rechtliche Hinweise",

        component: function () {
            return import(
                /* webpackChunkName: "rechtlichehinweise" */ "../views/rechtlicheHinweise.vue"
            )
        },
        meta: {
            requiresAuth: false,
            requiresAdmin: false,
            showInDrawer: false,
            category: "",
            title: "",
            symbol: "",
            info: ""
        }
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})


export default router
