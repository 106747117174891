<template>
    <div>
        <v-container>
            <v-row no-gutters>
                <v-col v-for="n in getEntries" :key="n.title" cols="12" sm="3">
                    <v-card class="pa-2" flat>
                        <router-link style="text-decoration: none; color: inherit;" :to="n.link">
                            <v-hover v-slot="{ hover }">
                                <v-card flat>
                                    <v-card-subtitle :style="{ 'color': hover ? '#b40055' : 'black' }">
                                        <v-icon :style="{ 'color': hover ? '#b40055' : 'black' }"> {{ n.symbol }}</v-icon> {{ n.title }} </v-card-subtitle>
                                    <v-card-text>{{ n.info }}</v-card-text>
                                </v-card>
                            </v-hover>

                        </router-link>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import router from "@/router";
export default {
    name: "overlayMenu",
    props: ["category"],
    data() {
        return {
        }
    },
    methods: {

    },
    computed: {
        getEntries() {
            const routes = router.options.routes
            let categories = {}
            let permissions=this.$store.state.auth.authClaims.permissions
            for (let route of routes) {
                if (route.meta && route.meta.showInDrawer && route.meta.showInDrawer === true && route.meta.category != undefined) {
                    if (!route.meta.requiresAuth || (route.meta.requiresAuth && this.$store.state.auth && this.$store.state.auth.isSignedIn)) {
                        if (permissions[route.meta.permission] === true) {
                            if (categories[route.meta.category] == undefined) {
                                categories[route.meta.category] = []
                            }
                            categories[route.meta.category].push({
                                link: route.path,
                                title: route.meta.title,
                                symbol: route.meta.symbol,
                                info: route.meta.info
                            })
                        }
                    }
                }
            }
            return categories[this.category]
        },
        isSignedIn() {
            return this.$store.state.auth.isSignedIn
        },
    },
    watch: {
    },
    mounted() {

    }
}
</script>

<style></style>