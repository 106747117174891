import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import facebookIcon from "@/components/icons/facebook.vue"
import instagramIcon from "@/components/icons/instagram.vue"
import youtubeIcon from "@/components/icons/youtube.vue"


Vue.use(Vuetify)
export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            /*  NEW BRAND COLORS 
                --new-primary-blue-nights: #{tokens.$new_primary_blue_nights};
                --new-primary-beere: #{tokens.$new_primary_beere};
                --new-primary-schwarzgrau: #22262f;
                --new-primary-schwarzgrau-a50: #909296;
                --new-primary-schwarzgrau-disabled: #75787d;
                --new-bg-hellgrau: #f2f2f3;
                --new-bg-hellgrau2: rgba(112, 112, 112, 20%);
                --new-primary-weiß: #ffffff;
                --new-primary-violettblau: #281e78;
                --new-secondary-violett: #8c0f78;
                --new-secondary-dunkelblau: #004ba0;
                --new-secondary-mittelblau: #008cc8;
                --new-secondary-hellblau: #55c3f0;
                --new-secondary-rot: #e60028;
                --new-spotcolor-grün: #00874b;
                --new-gradient-start-color: var(--new-secondary-dunkelblau);
                --new-gradient-end-color: #251f75;
            
                // backgrounds
                $new_bg_hellgrau: #f2f2f3;
            
                // primary colors
                $new_primary_weiß: #ffffff;
                $new_primary_blue_nights: #281e78;
                $new_primary_beere: #b40055;
                $new_primary_schwarzgrau: #22262f;
            
                // secondary colors
                $new_secondary_violett: #8c0f78;
                $new_secondary_dunkelblau: #004ba0;
                $new_secondary_mittelblau: #008cc8;
                $new_secondary_hellblau: #55c3f0;
                $new_secondary_rot: #e60028;
            
                // spot colors
                $new_spotcolor_grün: #00874b;
            
            */

            light:
            {
                primary: "#281e78",
                secondary: "#008cc8",
                accent: "#b40055",
                error: "#e60028",
                info: "#55c3f0",
                success: "#00874b",
                warning: "#008cc8",
            }
        }
    },
    icons: {
        values: {
            facebookIcon: {
                component: facebookIcon,
            },
            instagramIcon: {
                component: instagramIcon,
            },
            youtubeIcon: {
                component: youtubeIcon,
            },
        },
    },

})
