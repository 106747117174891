import { firestore } from "@/plugins/firebase"
import {
    doc,
    collection,
    query,
    updateDoc,
    onSnapshot
} from "firebase/firestore"

export default  {
    state: {
        allBestellungen: {},
        allBestellungenArray:[]
    },
    mutations: {},
    actions: {
        async getAllBestellungen({ state }) {
            const datapath = "/vertragsorder"
            const vOrderRef = collection(firestore, datapath)
            const vOrderq = query(vOrderRef)

            onSnapshot(vOrderq, (querySnapshot) => {
                state.allBestellungenArray=[];
                querySnapshot.forEach((doc) => {
                    //console.log(doc.data())
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.allBestellungen[doc.id] = docdata
                    state.allBestellungenArray.push(docdata)
                })
            })
        },
        async nudgeBestellung({ state },{ bestellid }) {
            console.log(state, bestellid)
            const docRef = doc(firestore, `/vertragsorder/${bestellid}`);
            const now=Math.floor(new Date().getTime() / 1000);
            try {
                await updateDoc(docRef,{
                    updated_ts: now,
                    TrackingCounter:0,
                    status: "OK"
                })
            } catch (error) {
                console.error(error);
            }
        }
    }
}

