<template>
    <div>
        <v-app-bar app flat color="white" light extension-height="64">
            <div>
                <v-img alt="NEW Energyview" class="xlogo shrink mr-2" contain
                    :src="require('@/assets/NEW_Energie_Logo_RBG_2023.svg')" />
            </div>
            <v-spacer></v-spacer>
            <div v-if="getMode() == 'test'" style="background-color: red;">TEST</div>
            <div class="mr-4">{{ getUsermailAddr }}</div>
            <div id="loginnew-dropdown"></div>
            <template #extension>
                <v-toolbar color="white" flat light>
                    <v-container>
                        <div>
                            <v-btn text v-for="link in Object.keys(navBarLinks)" :key="link"
                                @click="onClickMenuCategory(link)">{{ link }}</v-btn>
                        </div>
                    </v-container>
                </v-toolbar>

            </template>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    name: "AppBar",
    data() {
        return {
            links: [],
            currentFirma: {},
            absolute: false,
            overlay: false,
        }
    },
    methods: {
        onClickMenuCategory(category) {
            this.$store.state.main.menuoverlay = !this.$store.state.main.menuoverlay
            if (this.$store.state.main.menuoverlay) {
                this.$store.state.main.currentCategory=category
            }

        },
        setCurrentFirma(firma) {
            this.$store.dispatch("switchFirma", firma)
        },
        getMode() {
            return process.env.NODE_ENV
        }
    },
    computed: {
        navBarLinks() {
            let routes = this.$router.options.routes
            let categories = {}
            let permissions=this.$store.state.auth.authClaims.permissions
            for (let route of routes) {
                if (route.meta && route.meta.showInDrawer && route.meta.showInDrawer === true && route.meta.category != undefined) {
                    if (!route.meta.requiresAuth || (route.meta.requiresAuth && this.$store.state.auth && this.$store.state.auth.isSignedIn)) {
                        if (permissions[route.meta.permission] === true) {
                            if (categories[route.meta.category] == undefined) {
                                categories[route.meta.category] = []
                            }
                            categories[route.meta.category].push({
                                link: route.path,
                                title: route.meta.title
                            })
                        }
                    }
                }
            }
            if (!this.$store.state.auth || !this.$store.state.auth.userprofile || !this.$store.state.auth.userprofile.email) {
                return []
            } 

            return categories
        },
        isSignedIn() {
            return this.$store.state.auth.isSignedIn
        },
        isFirmenArray() {
            if (!this.$store.state.auth.firmen) { return }
            return this.$store.state.auth.firmen.length > 1 ? true : false
        },
        getCurrentFirma() {
            return this.$store.state.auth.currentFirma ? this.$store.state.auth.currentFirma : {}
        },
        getCurrentFirmaName() {
            return this.$store.state.auth.currentFirma ? this.$store.state.auth.currentFirma.name : ""
        },
        getFirmenList() {
            return this.$store.state.auth.firmen
        },
        getUsermailAddr() {
            return this.$store.state.auth.userprofile ? this.$store.state.auth.userprofile.email : ""
        }
    },
    watch: {

        getFirmenList() {
            if (!this.$store.state.auth.currentFirma) {
                this.setCurrentFirma(this.$store.state.auth.firmen[0])
            }
            this.currentFirma = this.$store.state.auth.currentFirma
        }
    },
    mounted() {
        console.log("mode",this.getMode())
    }
}
</script>

<style>
/* Profiltext */
#loginnew-dropdown .login-new-avatar span {
    display: none;
}

header.v-app-bar {
    contain: unset;
    transform: none !important;
    overflow: visible;
}

.xlogo {
    display: inline-block;
    width: auto;
    height: 45px;
}

</style>